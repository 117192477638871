/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client2.batchtree

import scala.collection.immutable._

import org.scalajs.dom

import typings.gojs.{mod => go}
import scala.scalajs.js
import org.scalablytyped.runtime.StringDictionary

import com.dalineage.common
import common.adt.ExplorerDataADT._

import com.dalineage.client2
import client2.adt.TreeExplorerADT

object BatchTreeComponent {
  import TreeExplorerADT.TreeExplorerUserAction
  import TreeExplorerADT.TreeNode

  case object ProcessedBatch extends TreeExplorerADT.NodeType
  case object SavedView extends TreeExplorerADT.NodeType
  case object Directory extends TreeExplorerADT.NodeType

  var batchTreeDiv: dom.html.Div = null
  var treeExplorerDiv: TreeExplorer.TreeExplorerDiv = null
  var userActionFn: TreeExplorerUserAction => Unit = null

  def msg(s: String): Unit = { batchTreeDiv.innerHTML = s }

  def init(div: dom.html.Div, userActionFn: TreeExplorerUserAction => Unit): Unit = {
    batchTreeDiv = div
    div.style.backgroundColor = client2.CSS.backgroundColor("explorer-window")
    div.tabIndex = 5
    div.id = "batch-tree"
    treeExplorerDiv = TreeExplorer.TreeExplorerDiv(div)
    this.userActionFn = userActionFn
  }

  val unixTimestemp2String: Option[Long] => String = (timestamp) =>
    timestamp
      .map(java.util.Date(_))
      .map(_.toString())
      .getOrElse(" - ")

  def open(explorerData: ExplorerData): Unit = {

    def dirToTree(dir: ExplorerDir, path: String): TreeNode = {
      val children = dir.nodes.map{ child =>
        child match
          case subdir: ExplorerDir =>
            dirToTree(subdir, path + "/" + dir.name)
          case batch: ProcessedBatch =>
            val output = batch.info.output
            val properties = ("user", batch.info.user) :: //keep key "user", used elsewhere
              ("Uploaded", unixTimestemp2String(batch.info.uploaded)) ::
              ("Gojs diagram", output.generateGoJsOutput.toString) ::
              ("Object browser", output.saveObjectBrowserData.toString) ::
              ("Description", batch.info.description) ::
              ("Source codes", batch.info.batchProcessingStats.get.totalFiles.toString) ::
              ("Syntax parsing errors",
                batch.info.batchProcessingStats.get.parserErrors.toString) ::
              ("Lineage analyzer failures",
                batch.info.batchProcessingStats.get.analyzerErrors.toString) ::
              ("Columns found", batch.info.batchProcessingStats.get.columnCount.toString) ::
              ("Relations found", batch.info.batchProcessingStats.get.relationCount.toString) ::
              Nil
            TreeNode(ProcessedBatch, batch.info.id, batch.info.name, properties, Nil)
          //tbd views
      }
      TreeNode(Directory, path + "/" + dir.name, dir.name, Nil, children)
    }
    val children = explorerData.dirs.map{ child => dirToTree(child, "") }
    val treeData = TreeNode(Directory, "root", "Select batch", Nil, children)
    treeExplorerDiv.renderTree(treeData, userActionFn)
    selectPublic()
    batchTreeDiv.focus()
  }

  def selectPublic(): Unit =
    val node = treeExplorerDiv.expandElementsByKey("/public")
    treeExplorerDiv.selectNode(node.get)

}
