/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client2

import org.scalajs.dom

object UploadDialog extends PopupWindow {
  val url = Some("uploadv2")
  override val height = 770
  override val width = 1020
}
