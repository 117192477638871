/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client2

import org.scalajs.dom
import dom.html
import scala.scalajs.js

import com.dalineage.common

object Workspace {
  import common.adt.ExplorerDataADT._
  import common.adt.ADT
  import common.adt.DiagramADT
  import Console.msgBox
  import batchtree.TreeExplorer
  import adt.TreeExplorerADT
  import TreeExplorerOps._

  import objectbrowser.ObjectBrowser

  var mainDiv: html.Div = _

  val location = dom.window.location
  val domain = s"${location.protocol}//${location.hostname}:${location.port}"

  trait WorkspaceUserAction
  case class KeyboardEvent(event: dom.KeyboardEvent) extends WorkspaceUserAction

  def init(
    mainDiv: html.Div,
    helpDiv: html.Div,
    panels: List[html.Div],
    queryParams: Map[String, String],
    origWorkspaceUserActionFn: WorkspaceUserAction => Unit): Unit = {

    this.mainDiv = mainDiv

    val workspaceUserActionFn: WorkspaceUserAction => Unit = action => action match
      case this.KeyboardEvent(event) if event.keyCode == 76 && !event.ctrlKey => // l
        loginLogout()
      case this.KeyboardEvent(event) if event.keyCode == 71 && !event.ctrlKey => // g
        Console.open()
      case this.KeyboardEvent(event) if event.keyCode == 85 && !event.ctrlKey => // u
        UploadDialog.open()
      case _ => origWorkspaceUserActionFn(action)

    helpDiv.addEventListener("keydown", { (ev: dom.KeyboardEvent) =>
      workspaceUserActionFn(this.KeyboardEvent(ev))
    })

    val explorerUserActionFn: TreeExplorerADT.TreeExplorerUserAction => Unit = { action =>
      action match
        case TreeExplorerADT.NodeSelected(node) =>
          msgBox(s"Node selected: ${node.key}")
          PropertyPanel.setProperties(node.properties)
        case TreeExplorerADT.NodeOpened(node) =>
          msgBox(s"Batch opened: ${node.key}")
          val properties = node.data.toMap
          val user = properties("user")
          val batchId = node.key
          val url =  s"?user=$user&id=$batchId"
          dom.window.location.search = url
        case TreeExplorerADT.ExplorerKeyboardEvent(ev)  =>
          workspaceUserActionFn(this.KeyboardEvent(ev))
        case _ =>
          msgBox("Unhandled action! " + action)
    }

    batchtree.BatchTreeComponent.init(panels(0), explorerUserActionFn)
    batchtree.BatchTreeComponent.msg("Loading user data.")
    loadExplorerData({ data =>
      batchtree.BatchTreeComponent.open(data)
    })

    queryParams.get("user").flatMap(user => queryParams.get("id").map(id => user -> id))
      .map{ case (user, id) =>

        val updateHelpText: String => Unit = println

        import diagram.GoJSDiagram.DiagramUserAction
        import common.adt.PropertyPanelADT._
        import adt.PropertyPanelADT._
        import PropertyPanel._
        val diagramUserActionFn: DiagramUserAction => Unit = { action =>
          action match
            case diagram.GoJSDiagram.LoadJson(url, fn) =>
              DomOps.getJson( url, fn )

            case diagram.GoJSDiagram.DiagramNodeSelected(optData) =>
              optData.map{ data =>
                val key = data("key").toString
                val typ = data("type").toString
                val caption = data("caption").toString
                val group = data.get("group").map(_.toString).getOrElse("-")
                val properties =
                  KeyValue( PropertyName.ObjectType.toString, typ, None ) ::
                  KeyValue( PropertyName.ObjectKey.toString, key, None ) ::
                  KeyValue( PropertyName.ObjectName.toString, caption, None ) ::
                  KeyValue( PropertyName.GroupKey.toString, group, None ) ::
                  Nil
                PropertyPanel.setProperties( properties )
              }

            case diagram.GoJSDiagram.ColumnSelected(optData) =>
              optData.map{ dataWithPanel =>
                val data = dataWithPanel._1
                val key = data("key").toString
                val tablekey = data("tablekey").toString
                val typ = "Projection column"
                val name = data("name").toString
                val properties =
                  KeyValue( PropertyName.ObjectType.toString, typ, None ) ::
                  KeyValue( PropertyName.ObjectName.toString, name, None ) ::
                  KeyValue( PropertyName.TableKey.toString, tablekey, None ) ::
                  KeyValue( PropertyName.ColumnKey.toString, key, None ) :: Nil
                PropertyPanel.setProperties( properties )
              }

            case diagram.GoJSDiagram.DiagramLinkSelected(Some(link)) =>
              import typings.gojs.{mod => go}
              println(s"DiagramLinkSelected")
              val fromKey = link.fromNode.key.toString
              val toKey = link.toNode.key.toString
              val fromPort = link.fromPort match
                case p:go.Panel => s"${p.portId}"
                case _ => "?"
              val toPort = link.toPort match
                case p:go.Panel => s"${p.portId}"
                case _ => "?"
              val properties =
                KeyValue( PropertyName.ObjectName.toString, "Link", None ) ::
                KeyValue( PropertyName.FromNode.toString, fromKey, None ) ::
                KeyValue( PropertyName.ToNode.toString, toKey, None ) ::
                KeyValue( PropertyName.FromPort.toString, fromPort, None ) ::
                KeyValue( PropertyName.ToPort.toString, toPort, None ) :: Nil
              PropertyPanel.setProperties( properties )

            case diagram.GoJSDiagram.KeyboardEvent(event)  =>
              workspaceUserActionFn(this.KeyboardEvent(event))
            case _ =>
        }

        val objectBrowserUserActionFn
            : ObjectBrowser.ObjectBrowserUserAction  => Unit = { action => action match {
          case ObjectBrowser.KeyboardEvent(event) =>
            workspaceUserActionFn(this.KeyboardEvent(event))
          case ObjectBrowser.SelectNode(key) =>
            println(s"selected key ${key}")
            /*
            com.dalineage.client.IDBDatabaseService(verbose = false).selectNode()(key)
              .>>(GoJSDiagram.open())
              .as[String](key)
              .map(GoJSDiagram.selectNode)
              */
          case ObjectBrowser.LoadQueryLineage(range) =>
            //redirect to url
            val url =  s"?user=$user&id=$id&source=${range.sourceId}"
            dom.window.location.search = url

          case ObjectBrowser.SelectCode(range) => range match
            case ADT.Range(sourceId, ADT.Position(lfrom, cfrom), ADT.Position(lto, cto)) =>
              Editor.open(user, id, sourceId.toString)
              Editor.selectCode(sourceId.toString, lfrom, cfrom, lto, cto)
              Console.msgBox(s"Code opened, sourceId $sourceId")
        }}

        val sourceid = queryParams.get("source")

        ObjectBrowser.open(
          panels(2),
          user,
          id,
          objectBrowserUserActionFn,
          updateHelpText)
        diagram.GoJSDiagram.open(panels(4), user, id, sourceid, diagramUserActionFn)
        Editor.init(panels(3))
        queryParams.get("source").fold
          ( Editor.open(user, id, "0") )
          ( Editor.open(user, id, _))
      }.getOrElse{
        panels(2).innerHTML = """
        <div class="obtext">&nbsp;</div>
        <ul>
        <li>
        <div class="obtext">Use arrow keys or mouse on the left
        panel to select batch</div>
        </li>
        <li>
        <div class="obtext">Press enter to load visualization diagram
        and see object browser data here</div>
        </li>
        <li>
        <div class="obtext">Register at info@dalineage.com
        to upload your own code</div>
        </li>
        </ul>
"""
        panels(4).innerHTML = """
        <div class="feedback">&nbsp;</div>
        <div class="feedback">Send feedback</div>
        <div class="feedback">Report bug</div>
        <div class="feedback">Request account:</div>
        <div class="feedback">&nbsp;</div>
        <div class="feedback">info@dalineage.com</div>
"""
      }

    PropertyPanel.init(panels(1))
  }

  def loadExplorerData(successFn: ExplorerData => Unit): Unit = {
    val dFn: ExplorerData => Unit = { data =>
      msgBox("User directory data loaded")
      successFn(data)
    }
    msgBox("Loading user directory data")
    DomOps.getJsonObject[ExplorerData]( "web/dashboard", dFn )
  }

  def loginLogout(): Unit =
    if (s"${js.Dynamic.global.user}" == "public")
      msgBox(s"Redirecting to login page")
      dom.window.location.replace("/login")
    else
      DomOps.sendRequest(dom.HttpMethod.GET, "web/logout", { text =>
        msgBox(s"Logged out, response: $text")
        DomOps.sendRequest(
          dom.HttpMethod.GET,
          "web/current_user",
          { user =>
            js.Dynamic.global.user = user
            msgBox(s"Logged out, current user is now $user")
            dom.document.location.reload()
          },
          noCache = true
        )
      })
}
