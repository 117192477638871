/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client2

import org.scalajs.dom
import org.scalajs.dom.{document, html, MouseEvent}
import scala.scalajs.js

import com.dalineage.common


object MainApp {
  import Console.msgBox
  import adt.PropertyPanelADT.{Button, KeyValue, HelpText, Property}
  import PropertyPanel.button

  def main(args: Array[String]): Unit = {
    val container = document.getElementById("container").asInstanceOf[html.Div]

    val helpHeight = 40

    container.style.height = s"calc(100vh - ${helpHeight}px)"

    // Create Panels and Separators
    val panelColors = Seq("#f0f0f0", "#e0e0e0", "#F0F0F0", "#c0c0c0", "#ffffff")
    val panels = panelColors.zipWithIndex.map { case (color, index) =>
      val panel = document.createElement("div").asInstanceOf[html.Div]
      panel.className = "panel"
      panel.style.width = s"${100.0 / panelColors.length}%"
      panel.style.backgroundColor = color
      panel.style.outline = "none"
      panel.tabIndex = index
      panel
    }

    val separators = (1 until panels.length).map { _ =>
      val separator = document.createElement("div").asInstanceOf[html.Div]
      separator.className = "separator"
      separator
    }

    // Append Panels and Separators to Container
    for (i <- panels.indices) {
      container.appendChild(panels(i))
      if (i < separators.length) {
        container.appendChild(separators(i))
      }
    }

    // Handle Dragging
    var isDragging = false
    var currentSeparatorIndex = -1
    val minPanelWidth = 50 // pixels


    separators.zipWithIndex.foreach { case (separator, index) =>
      separator.onmousedown = (e: MouseEvent) => {
        isDragging = true
        currentSeparatorIndex = index
        e.preventDefault()
      }
    }

    val helpDiv = document.getElementById("help").asInstanceOf[html.Div]
    helpDiv.id = "helpDiv"
    helpDiv.tabIndex = 15
    helpDiv.style.border = "1px solid black"
    helpDiv.style.backgroundColor = "lightgray"
    helpDiv.style.textAlign = "center"
    helpDiv.style.verticalAlign = "middle"
    helpDiv.style.width = "100%"
    helpDiv.style.height = s"${helpHeight}px"
    helpDiv.style.lineHeight = s"${helpHeight}px"

    def updateHelpText(): Unit = {
      val userHelp = s"Logged in as <b>${js.Dynamic.global.user}</b>"
      val upload = Button("upload code", 85, "helpDiv")
      val uploadHelp = s"Press u to ${button(upload)}."
      val login = Button("login/logout", 76, "helpDiv")
      val loginHelp = s"Press l to ${button(login)}."
      val console = Button("view log console", 71, "helpDiv")
      val consoleHelp = s"Press g to ${button(console)}."
      val oButton = Button("o", 79, "helpDiv")
      val pButton = Button("p", 80, "helpDiv")
      val bButton = Button("b", 66, "helpDiv")
      val cButton = Button("c", 67, "helpDiv")

      val buttons = s"${button(oButton)} ${button(pButton)} ${button(bButton)} ${button(cButton)}"
      val panelsHelp = s"Press $buttons to shrink opening/property/object browser/code panels."
      val etwas = "@"
      val sp = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
      helpDiv.innerHTML = s"$userHelp $loginHelp $sp $panelsHelp $sp $uploadHelp $consoleHelp"
    }

    dom.window.onmousemove = (e: MouseEvent) => {
      if (isDragging && currentSeparatorIndex >= 0) {
        val containerRect = container.getBoundingClientRect()
        val totalWidth = containerRect.width
        val mouseX = e.clientX - containerRect.left

        // Calculate cumulative widths up to the left panel
        val leftPanelsWidth = (0 until currentSeparatorIndex).map(panels(_).offsetWidth).sum
        val leftSeparatorsWidth = (0 until currentSeparatorIndex).map(separators(_).offsetWidth).sum

        val leftOffset = leftPanelsWidth + leftSeparatorsWidth

        // Calculate new widths
        val newLeftPanelWidth = mouseX - leftOffset
        val rightPanelWidth = panels(currentSeparatorIndex + 1).offsetWidth
        val delta = newLeftPanelWidth - panels(currentSeparatorIndex).offsetWidth
        val newRightPanelWidth = rightPanelWidth - delta

        // Enforce minimum widths
        if (newLeftPanelWidth >= minPanelWidth && newRightPanelWidth >= minPanelWidth) {
          val totalPanelsWidth = panels.map(_.offsetWidth).sum + separators.map(_.offsetWidth).sum

          panels(currentSeparatorIndex).style.width = s"${(newLeftPanelWidth / totalPanelsWidth) * 100}%"
          panels(currentSeparatorIndex + 1).style.width = s"${(newRightPanelWidth / totalPanelsWidth) * 100}%"
        }
      }
    }

    dom.window.onmouseup = (_: MouseEvent) => {
      isDragging = false
      currentSeparatorIndex = -1
    }

    val p = panels.map(_.asInstanceOf[html.Div]).toList
    val params = DomOps.parseQueryString()

    // Function to shrink the i-th panel to minimum width
    def shrinkPanelToMinimum(i: Int): Unit = {
      if (i >= 0 && i < panels.length) {
        val containerWidth = container.getBoundingClientRect().width
        val totalSeparatorWidth = separators.map(_.offsetWidth).sum

        // Set the i-th panel's width to minimum
        panels(i).style.width = panels(i).offsetWidth match
          case width if width < minPanelWidth + 8 //magic number
            => s"${100.0 / panelColors.length}%"
          case _ => s"${(minPanelWidth / containerWidth) * 100}%"

        // Distribute the remaining width among other panels proportionally
        val remainingWidth = containerWidth - panels(i).offsetWidth - totalSeparatorWidth
        val otherPanels = panels.indices.filter{ index =>
          index != i && panels(index).offsetWidth > minPanelWidth + 8//magic number
        }
        val totalCurrentWidth = otherPanels.map(panels(_).offsetWidth).sum

        otherPanels.foreach { idx =>
          val currentWidth = panels(idx).offsetWidth
          val newWidth = (currentWidth / totalCurrentWidth) * remainingWidth
          // Enforce minimum width
          val adjustedWidth = Math.max(newWidth, minPanelWidth)
          panels(idx).style.width = s"${(adjustedWidth / containerWidth) * 100}%"
        }
      }
    }

    val workspaceUserActionFn: Workspace.WorkspaceUserAction => Unit = {
      case Workspace.KeyboardEvent(event) if event.keyCode == 79 =>
        shrinkPanelToMinimum(0)
      case Workspace.KeyboardEvent(event) if event.keyCode == 80 =>
        shrinkPanelToMinimum(1)
      case Workspace.KeyboardEvent(event) if event.keyCode == 66 =>
        shrinkPanelToMinimum(2)
      case Workspace.KeyboardEvent(event) if event.keyCode == 67 =>
        shrinkPanelToMinimum(3)
      case Workspace.KeyboardEvent(event) =>
        println(s"Workspace Keyboard event: $event")
        Workspace.mainDiv.dispatchEvent(event)
      case unhandled @ _ =>
        println(s"Unhandled action: $unhandled")
    }

    DomOps.sendRequest(
    dom.HttpMethod.GET, "web/current_user", { user =>
      js.Dynamic.global.user = user
      updateHelpText()
      Workspace.init(container, helpDiv, p, params, workspaceUserActionFn)
    },
    noCache = true)
  }
}
